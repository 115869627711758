:root {
   --primary-color: #004a8f;
   --secondary-color: #c0cfd8;
   --text-color: #fdfdfd;
   --text-title-category: #303030;

   --white-700: #fdfdfd;
   --gray-800: #303030;
   --text-categories: #004a8f;
   --background-app: #fdfdfd;
   --text-primary: #303030;
   --background-categories: #c0cfd8;
   --text-label-price-product: #2e2e2e;
   --icon-cart: #2e2e2e;
   --color-dark: #fdfdfd;
   --background-input: #fdfdfd;
   --color-star-review: #505050;
   --text-sub: #505050;
   --background-all-you-can-eat: #5f9ea0;
   --input-search: #353535;
   --background-cart: #fdfdfd;
   --border-products: #e3e3e3;
   --disabled: #dddddd;
   --disabled-text: #6b6b6b;

   --background-login: #fdfdfde6;

   --text-observation: #6b6b6b;
   --background-content: #f9f9f9;
   --red: #c93737;
   --green: #46a146;
   --yellow: #ffc118;
   --blue: #3085d6;
}

.dark-mode:root {
   --primary-color: #2f2f2f !important;
   --secondary-color: #3d3d3d !important;
   --text-color: #fafafa !important;
   --text-title-category: #fdfdfd !important;

   --white-700: #fdfdfd;
   --gray-800: #303030;
   --text-categories: #cfa741;
   --background-input: #3d3d3d;
   --background-app: #212121;
   --text-primary: #e5e2e2;
   --background-categories: #3d3d3d;
   --text-label-price-product: #cfa741;
   --color-dark: #cfa741;
   --text-sub: #d8d8d8;
   --color-star-review: #d8d8d8;
   --background-all-you-can-eat: #223939;
   --input-search: #f3f3f3;
   --background-cart: #444444;
   --icon-cart: #cfa741;
   --border-products: #535353;
   --disabled: #dddddd;
   --disabled-text: #6b6b6b;

   --background-login: #303030cf;

   --text-observation: #acacac;
   --background-content: #373737;
   --red: #c05c5c;
   --green: #69c969;
   --yellow: #f8c953;
   --blue: #225789;
}

* {
   font-size: 1rem;
   -webkit-box-sizing: border-box;
   margin: 0;
   padding: 0;
   font-size: 1rem;
   box-sizing: border-box;
   text-rendering: optimizeLegibility;
   scroll-behavior: smooth;

   &::-webkit-scrollbar {
      width: 0;
   }

   &::-webkit-scrollbar-track {
      background: none;
   }

   &::-webkit-scrollbar-thumb {
      background: none;
   }
}

html,
body {
   font-family: 'Poppins', sans-serif !important;
   color: var(--gray-800);
   background: var(--background-app);
   font-size: 97.75%;
   height: 100vh;
   height: calc(var(--vh, 1vh) * 100);
   font-weight: 300;
   //overflow-x: hidden;
}

html {
   scroll-behavior: smooth;
   scroll-padding-top: 90px;
}

//-swal--------------------------------------------------------------
.swal2-popup {
   background: var(--background-app) !important;

   .swal2-content {
      color: var(--text-primary) !important;
   }

   .swal2-styled {
      &.swal2-confirm {
         background: var(--blue) !important;
      }

      &.swal2-cancel {
         background: var(--red) !important;
      }
   }
}

.swal2-icon.swal2-warning {
   border-color: var(--yellow) !important;
   color: var(--yellow) !important;
}

//-material-Angular--------------------------------------------------
.mat-form-field-appearance-legacy {
   .mat-form-field-label {
      color: var(--input-search) !important;
   }

   .mat-form-field-underline {
      background-color: var(--border-products) !important;
   }
}

mat-form-field {
   width: 100%;
   padding: 0 10px;
}

.mat-select-arrow {
   color: var(--text-primary) !important;
}

.mat-input-element {
   padding: 5px !important;
   color: var(--text-primary) !important;
}

.mat-bottom-sheet-container {
   background: var(--background-app) !important;
}

.mat-radio-label {
   justify-content: space-between !important;
   flex-direction: row-reverse !important;
}

.mat-radio-label-content {
   padding: 0 !important;
}

.mat-radio-outer-circle {
   border-width: 0.0625rem !important;
   border-color: var(--text-primary) !important;
}

.mat-radio-inner-circle {
   background-color: var(--text-primary) !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
   stroke: var(--primary-color) !important;
}

span.mat-radio-container {
   display: grid !important;
}

.mat-tab-label-content {
   color: var(--text-primary) !important;
}

//--------------------------------------------------------------
img {
   opacity: 0.9;
}

input {
   font: 400 1rem 'Poppins', sans-serif;
   width: 100%;
   padding: 0.625rem;
   margin: 0.625rem 0;
   background: var(--background-input);
   color: var(--text-primary) !important;
}

hr {
   background-color: var(--border-products);
}

textarea {
   font: 400 1rem 'Poppins', sans-serif;
   width: 100%;
   padding: 0.625rem;
   border-radius: 0.3125rem;
   margin: 0.625rem 0;
   color: var(--text-primary);
}

textarea::placeholder,
input::placeholder {
   color: var(--border-products) !important;
}

input[type='radio'] {
   margin: 0 !important;
}

.group-buttons {
   display: flex;
   gap: 10px;
}

button {
   border-radius: 0.3125rem;
   height: 3.125rem;
   border: 0;
   width: 100%;
   cursor: pointer;
   font-family: 'Poppins', sans-serif !important;
   color: var(--text-color);
   background: var(--primary-color);

   &:disabled {
      background: none !important;
      color: var(--disabled) !important;
   }

   &.btn-defaut {
      background: none !important;
      border: 1px solid var(--text-primary);
      color: var(--text-primary);
   }

   &.btn-cancel {
      background: none !important;
      border: none !important;
      color: var(--red);
   }

   &.btn-delete {
      background: none;
      width: 50px;
      border: 1px solid var(--red);

      i {
         margin: 0 !important;
         color: var(--red);
         font-size: 1.2rem;
      }
   }

   i {
      margin-right: 10px;
   }
}

a {
   text-decoration: none;
}

li {
   list-style-type: none;
}

.label-input {
   font-size: 1rem;
   color: var(--text-primary);
}

//-modal-----------------------------------------------------------

.modal {
   background: var(--background-app);
   border-radius: 0.5rem;
   //box-shadow: 0 0.125rem 0.5rem #333;
   min-width: 24rem;
   position: fixed;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 11;
   color: var(--text-primary);

   .modal-content {
      padding: 1.5rem;

      button {
         width: 100%;
         margin-top: 1.25rem;
      }
   }

   &.multiple-dialog {
      z-index: 13;
   }
}

.overlay {
   background: rgba($color: #000000, $alpha: 0.7);
   height: 100vh;
   left: 0;
   position: fixed;
   top: 0;
   width: 100vw;
   z-index: 10;

   &.multiple-dialog {
      z-index: 12;
   }
}

.header-modal {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 10px;

   .title-modal {
      font-size: 0.8rem;
   }

   i {
      font-size: 1.3rem;
      color: var(--text-primary);
      cursor: pointer;

      &.icon-close {
         font-size: 2rem;
      }
   }
}

.group-buttons-footer {
   position: absolute;
   bottom: 0;
   width: 100%;
   padding: 1.5rem;
   gap: 1.875rem;
   display: flex;
   justify-content: space-between;
   align-items: center;
   background: var(--background-app);
   border-top: 0.0625rem solid var(--border-products);

   .btn-add {
      width: 100%;
      background: var(--primary-color);
      color: var(--text-color);
   }
}

.icon-delete {
   color: var(--red);
   font-size: 1.3rem;
}

.btn-more-minus {
   display: flex;
   gap: 10px;
   align-items: center;

   .btn-minus {
      width: 3.125rem;
      color: var(--red);
      background: none;
      font-size: 2rem;
   }

   .quantity-product {
      width: 20px;
      display: flex;
      justify-content: center;
   }

   .btn-more {
      width: 3.125rem;
      background: none;
      color: var(--text-primary);
      font-size: 2rem;
   }
}

.warning-18 {
   background: var(--red);
   text-align: center;
   padding: 0.625rem;
   border-radius: 0.3125rem;
   color: var(--white-700);
   margin-bottom: 0.625rem;

   i {
      margin-right: 0.3125rem;
   }
}

.warning-18-list {
   color: var(--red);
   font-size: 1.1rem;
   margin-left: 0.625rem;
}

.all-you-can-eat {
   text-align: center;
   padding: 0.125rem;
   border-radius: 1rem;
   color: #705454;
   border: 0.0625rem solid;
   margin-bottom: 0.625rem;
   font-size: 0.75rem !important;

   &.list {
      font-size: 0.8rem;
   }

   i {
      margin-right: 0.3125rem;
   }
}

.toast-container {
   .ngx-toastr {
      width: 100% !important;
      margin-left: auto;
      margin-right: auto;

      &.toast-success {
         background-color: var(--green);
      }
   }
}

.mat-typography {
   p {
      margin: 0 !important;
   }
}

//-bottom-sheet-----------------------------------------------
.header-bottom-sheet {
   text-align: end;
   padding-top: 10px;

   i {
      font-size: 1.3rem;
      color: var(--text-primary);
   }
}

//-desk----------------------------------------------------------
@media (min-width: 979px) {
   * {
      &::-webkit-scrollbar {
         width: 10px !important;
      }

      &::-webkit-scrollbar-track {
         background: var(--secondary-color);
      }

      &::-webkit-scrollbar-thumb {
         background: var(--primary-color);
      }
   }

   mat-form-field {
      width: 100%;
   }

   .mat-input-element {
      padding: initial;
   }

   .mat-select {
      padding: 5px;
   }

   button,
   .icon-delete {
      cursor: pointer;
   }

   .modal {
      width: 680px !important;
      overflow: auto;
      overflow-x: hidden;
      right: 0 !important;
      transform: initial;
      top: 0;
      left: initial;
   }

   .header-bottom-sheet {
      i {
         cursor: pointer;
      }
   }

   .mat-dialog-container {
      width: 373px !important;
      margin: auto;
   }
}
